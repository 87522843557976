/** form */

.message-form
{
    margin: 1.5em 20px;
}

.message-form .field
{
    margin: 1.5em 0;
}

.message-form .field-actions
{
    margin: 1.5em 0;
}

/** conversation */

.list .conversation.list-item
{
    padding-right: 80px;
    position: relative;
}

.conversation-bcc
{
    background: rgba(0,0,0,0.2);
    border-radius: 2px;
    color: white;
    display: inline-block;
    margin: 0;
    padding: 0 5px;
    position: absolute;
    top: 10px;
    right: 20px;
}

/** messages */

.messages .message
{
    margin: 1.5em 20px;
}

.messages .message .meta
{
    color: #AAA;
    margin: 0;
    padding: 0.5em 10px;
    text-align: right;
}

.messages .message .content
{
    background: #CCC;
    border-radius: 2px;
    margin: 0;
    padding: 0.75em 10px;
}
