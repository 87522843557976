html,
body,
#page
{
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0;
    position: relative;
    width: 100%;
}

#navigation,
#main
{
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 0;
}

#navigation
{
    left: 0;
    width: 20%;
    z-index: 500;
}

#main
{
    left: 20%;
    width: 80%;
    z-index: 400;
}

@media screen and (max-width: 480px) {
    
    #navigation,
    #main
    {
        left: 0;
        width: 100%;
    }
    
    #main
    {
        transition: left 0.25s;
        z-index: 600;
    }
    
    #main.with-nav
    {
        left: 80%;
    }
    
}
