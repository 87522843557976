#map
{
    height: 200px;
    margin: 0;
    padding: 0;
}

#map.block
{
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin: 1.5em 0;
    padding: 0;
}
