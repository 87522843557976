@media print {
    
    html,
    body,
    #page
    {
        height: auto !important;
        width: auto !important;
    }
    
    #navigation,
    #titlebar,
    .toolbar
    {
        display: none !important;
    }
    
    #main,
    #banner,
    .fixed
    {
        bottom: 0 !important;
        left: 0 !important;
        overflow-x: hidden !important;
        overflow-y: auto !important;
        position: static !important;
        right: 0 !important;
        top: 0 !important;
        height: auto !important;
        width: auto !important;
    }
    
    #report-canvas
    {
        margin: 0;
        padding: 0;
    }

}
