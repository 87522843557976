button,
input,
label
{
    display: inline-block;
}

*:focus
{
    outline: none;
}

/** input */

input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="password"],
input[type="text"],
input[type="time"]
{
    background: white;
    border: 1px solid #CCC;
    border-radius: 2px;
    margin: -5px 0;
    outline: none;
    padding: 4px 8px;
}

input[type="date"]:focus,
input[type="datetime-local"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="text"]:focus,
input[type="time"]:focus
{
    border: 1px solid #8AD;
}

input[type="date"][disabled],
input[type="datetime-local"][disabled],
input[type="email"][disabled],
input[type="password"][disabled],
input[type="text"][disabled],
input[type="time"][disabled]
{
    background: none;
}

/** button */

button,
.button
{
    background: #888;
    border: none;
    border-radius: 2px;
    color: white;
	cursor: pointer;
    display: inline-block !important;
    margin: -5px 0;
    outline: none;
    padding: 5px 10px;
    text-align: center;
}

button:focus,
.button:focus,
button:hover,
.button:hover
{
    background: #666;
}

/** checkbox */

input[type="checkbox"]
{
	margin-right: 5px;
}

/** html */

span.html
{
    display: inline-block;
    margin: 0 9px;
}

/** textarea */

textarea
{
    background: white;
    border: 1px solid #CCC;
    border-radius: 2px;
    margin: -5px 0 -12px 0;
    outline: none;
    padding: 4px 8px;
    resize: none;
    width: 100%;
}

textarea:focus
{
    border: 1px solid #8AD;
}

textarea[disabled]
{
    background: none;
}

/** form-sizes */

.form-size-auto
{
    width: auto;
}

.form-size-m
{
    width: 50%;
}

.form-size-l
{
    width: 100%;
}

/** button group */

.button-group
{
    margin: 0 -5px;
    padding: 0;
}

.button-group:before,
.button-group:after
{
    clear: both;
    content: " ";
    display: block;
}

.button-group button,
.button-group .button
{
    float: left !important;
    margin: -5px 5px !important;
    padding: 5px 10px !important;
}

/** border buttons */

button.button-border,
.button.button-border
{
    background: none !important;
    border: 1px solid #CCC !important;
    color: #888 !important;
}

button.button-border:hover,
.button.button-border:hover
{
    background: #BBB !important;
    border: 1px solid #BBB !important;
    color: white !important;
}

/** borderless buttons */

button.button-borderless,
.button.button-borderless
{
    background: none !important;
    color: #888 !important;
}

button.button-borderless:focus,
.button.button-borderless:focus,
button.button-borderless:hover,
.button.button-borderless:hover
{
    background: #BBB !important;
    color: white !important;
}

button.button-borderless:focus *,
.button.button-borderless:focus *,
button.button-borderless:hover *,
.button.button-borderless:hover *
{
    color: white !important;
}

button.button-borderless.active,
.button.button-borderless.active
{
    background: #BBB !important;
    color: white !important;
}

/** default buttons */

button.button-default,
.button.button-default
{
    background: #76B243 !important;
}

button.button-default:focus,
.button.button-default:focus,
button.button-default:hover,
.button.button-default:hover
{
    background: #592 !important;
}

.layout-learning-master button.button-default,
.layout-learning-master .button.button-default
{
    background: #660099 !important;
}

.layout-learning-master button.button-default:focus,
.layout-learning-master .button.button-default:focus,
.layout-learning-master button.button-default:hover,
.layout-learning-master .button.button-default:hover
{
    background: #330066 !important;
}

/** desructive buttons */

button.button-delete:focus,
.button.button-delete:focus,
button.button-delete:hover,
.button.button-delete:hover
{
    background: #C00 !important;
}

button.button-delete .icon,
.button.button-delete .icon,
{
    color: #BBB !important;
}

button.button-delete:focus .icon,
.button.button-delete:hover .icon
button.button-delete:focus .icon,
.button.button-delete:hover .icon
{
    color: #C00 !important;
}

button.button-white,
.button.button-white
{
    background: white;
}

/** dividers */

.horizontal-divider
{
    background: #CCC;
    float: left;
    height: 21px;
    margin: 0;
    width: 1px;
}

/** input-group */

.input-group
{
    background: #DDD;
    border-radius: 2px;
    color: black;
    cursor: pointer;
    margin: -5px 0;
    padding: 5px 8px;
    padding-right: 60px;
    position: relative;
}

.input-group:hover
{
    background: #CCC;
}

.input-group-input
{
    display: none;
}

.input-group-action
{
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.input-group-action .button
{
    background: none !important;
    color: black !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 5px 10px !important;
}

/** */

.with-space-b
{
    margin-left: 10px;
}

.with-space-a
{
    margin-right: 10px;
}

@media screen and (max-width: 480px) {
    
    .button-group button,
    .button-group .button
    {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
    
}
