#navigation
{
    background: #3F403D;
}

#navigation ul
{
    margin: 0;
    padding: 0;
}

#navigation li
{
    list-style: none;
    margin: 0;
    padding: 0;
}

#navigation a
{
    color: #BBB;
    display: block;
    margin: 0;
    padding: 10px 10px;
    padding-left: 50px;
    text-decoration: none;
    text-shadow: black 0 1px 0;
}

#navigation a:hover
{
    background: #333;
    color: white;
}

#navigation a.active
{
    background: #333;
}

#navigation a .icon
{
    margin: 0;
    margin-left: -30px;
    margin-right: 5px;
    padding: 0;
    width: 25px;
}

/** navigation group */

#navigation .navigation-group
{
    border-bottom: 1px solid #555;
    margin: 0;
    padding: 1.5em 0;
    position: relative;
}

#navigation .navigation-header
{
    color: #888;
    padding: 10px 20px;
    position: relative;
}

#navigation .navigation-header a
{
    color: #888;
    margin: -10px -20px;
    padding: 10px 20px;
}

#navigation .navigation-header a:hover
{
    color: white;
}

#navigation .navigation-title
{
    font-size: 1em;
    line-height: 1.5em;
    margin: 0;
    padding: 0;
    padding-right: 40px;
    text-shadow: black 0 1px 0;
}

#navigation .navigation-action
{
    position: absolute;
    right: 15px;
    top: 10px;
}

#navigation .navigation-action .icon
{
    margin: 0;
    padding: 0;
}
