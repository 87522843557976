#titlebar
{
    background: white;
    border-bottom: 1px solid #333;
    color: #666;
    height: 80px;
    left: 0;
    padding: 30px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 500;
}

#titlebar:before,
#titlebar:after
{
    clear: both;
    content: " ";
    display: block;
}

#titlebar .titlebar-left
{
    float: left;
}

#titlebar .titlebar-title
{
    float: left;
    margin: 0;
    padding: 0 10px;
    text-align: left;
}

#titlebar .titlebar-right
{
    float: right;
    margin: 0 -5px;
    padding: 0;
}

#titlebar .titlebar-item
{
    float: left;
}

#titlebar a
{
    border-radius: 2px;
    color: #999;
    margin: -15px 0;
    padding: 15px 20px;
}

#titlebar a:hover
{
    background: #BBB;
    color: white;
}

#titlebar a.highlighted
{
    color: #F15922;
}

@media screen and (max-width: 480px) {
    
    #titlebar
    {
        padding: 30px 10px;
    }
    
    #titlebar .titlebar-title
    {
        padding: 0;
    }
    
    #titlebar .titlebar-right
    {
        margin: 0;
    }
    
    #titlebar a
    {
        margin: -15px 0;
        padding: 15px 10px;
    }
    
}

@media screen and (min-width: 480px) {
    
    #titlebar a.toggle-nav
    {
        display: none;
    }
    
}
