.preb-banner:before,
.preb-banner:after
{
    clear: both;
    content: '';
    display: block;
}

.preb-banner .button-container {
    float: right;
}

.preb-banner .button-container .button {
    background: #36a3d9 !important;
    display: block !important;
}

.preb-banner .button-container .button:hover {
    background: #18b !important;
}

/** organization **/

.preb-banner.large {
    background-color: white;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 160px 110px;
    border-radius: 2px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 50px 20px;
}

/** dashboard **/

.preb-banner.small {
    background-color: white;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 95px 84px;
    float: right;
    height: 84px;
    margin: -20px 0;
    padding: 0 20px;
    padding-top: 30px;
    padding-left: 145px;
}

@media screen and (max-width: 1000px) {

    .preb-banner {
        background: none !important;
        float: none !important;
        height: auto !important;
        margin: 0 !important;
        margin-top: 50px !important;
        padding: 0 !important;
    }

    .preb-banner .button-container {
        float: none !important;
    }

}
