#report-canvas
{
    background: #BBB;
    margin: 0;
    padding: 20px;
}

#report-output
{
    background: white;
    margin: 0;
    padding: 10px 20px;
}

#report-output *
{
    font-family: Helvetica, sans-serif;
    font-size: 11pt;
    line-height: 1.4em;
}

#report-output h1
{ 
    font-size: 1.5em; 
}

#report-output table
{
    border: none;
    border-collapse: collapse;
    width: 100%;
}

#report-output table tr
{
    border-bottom: 1px solid #CCC;
}

#report-output table td
{
    margin: 0;
    padding: 0.5em 1em 0.5em 0;
    vertical-align: top;
}
