.field
{
    margin: 1.5em 0;
    padding: 0;
}

.field:before,
.field:after
{
    clear: both;
    content: " ";
    display: block;
}

.field .field-label
{
    float: left;
    margin: 0;
    margin-right: -25%;
    padding: 0;
    padding-right: 10px;
    text-align: right;
    width: 25%;
}

.field .field-label.without-indent
{
    text-align: left;
}

.field .field-input
{
    float: left;
    margin: 0;
    margin-left: 25%;
    padding: 0;
    width: 75%;
}

.field-description
{
    color: #999;
    margin-left: 25%;
    width: 75%;
}

.field .field-error
{
    background: #C33;
    border-radius: 2px;
    color: white;
    display: none;
    float: left;
    margin: -5px 0;
    margin-top: 10px;
    margin-left: 25%;
    padding: 5px 9px;
    width: 75%;
}

.field .field-error.without-margin
{
    margin: -5px 0;
    margin-left: 25%;
}

.field.with-error .field-error
{
    display: block;
}

.field.with-error input[type="date"],
.field.with-error input[type="datetime-local"],
.field.with-error input[type="email"],
.field.with-error input[type="password"],
.field.with-error input[type="text"],
.field.with-error input[type="time"]
{
    border: 1px solid #C33;
}

.field.with-error textarea
{
    border: 1px solid #C33;
}

/** form-actions */

.form-actions
{
    margin: 2.25em 0;
    margin-left: 25%;
}

.form-actions:after
{
    clear: both;
    content: " ";
    display: block;
}

/** stacked field */

.stacked .field-label
{
    margin: 0 !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
    text-align: left !important;
    width: 100% !important;
}

.stacked .field-input
{
    margin: 0 !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
    width: 100% !important;
}

.stacked .field-error
{
    margin: 0 !important;
    margin-bottom: 0.5em !important;
    padding: 5px 9px !important;
    width: 100% !important;
}

/** classes */

.with-indent
{
    margin-left: 25% !important;
    width: 75% !important;
}

.without-indent
{
    margin-left: 0 !important;
    width: 100% !important;
}

@media screen and (max-width: 480px) {
    
    .field-input-group
    {
        margin-top: -10px !important;
        margin-bottom: -10px !important;
    }
    
    .field-input-group input,
    .field-input-group button,
    .field-input-group .button
    {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
    
    .field-input-group .chosen-container
    {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
    
    .field-input-group .chosen-search input
    {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    
}
