.progress
{
    background: #DDD;
    border-radius: 2px;
    height: 4px;
    width: 100%;
}

.progress .progress-item
{
    background: #76B243;
    border-radius: 2px;
    float: left;
    height: 4px;
}

.progress.large
{
    border-radius: 0;
    height: 40px;
}

.progress.large .progress-item
{
    border-radius: 0;
    height: 40px;
}

/** list */

.list .progress
{
    margin: 8px 0;
}

@media screen and (max-width: 480px) {
    
    .list .progress
    {
        margin: 10px 0;
    }
    
}
