body {
    font-family: "Myriad Pro", "Helvetica", sans-serif;
}

.chosen-container {
    font-size: 14px;
}

.chosen-container-single .chosen-single {
    background: #DDD;
    border: none;
    border-radius: 2px;
    box-shadow: none;
    color: black;
    height: auto;
    line-height: 1.5em;
    margin: -5px 0;
    padding: 5px 9px;
    text-decoration: none;
}

.chosen-container-active .chosen-single {
    background: #BBB;
    border: none;
    box-shadow: none;
}

.chosen-container-active.chosen-with-drop .chosen-single {
    background: #BBB;
    border: none;
    box-shadow: none;
}

.chosen-container-single .chosen-single span {
    margin-right: 20px;
}

.chosen-container-single .chosen-single div {
    width: 20px;
}

.chosen-container-single .chosen-single div b {
    background-position: 100% 13px !important;
    height: 100%;
    width: 100%;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: 100% -37px !important;
}

.chosen-container .chosen-drop {
    background: white;
    border: 1px solid #BBB;
    border-top: none;
    border-radius: 0 0 2px 2px;
    box-shadow: none;
    margin-top: 5px;
}

.chosen-disabled
{
    opacity: 1.0 !important;
}

.chosen-disabled .chosen-single
{
    color: #888;
}

/** chosen-search */

.chosen-container-single .chosen-search {
    border-bottom: 1px solid #BBB;
    margin: 0;
    padding: 0;
}

.chosen-container-single .chosen-search input[type="text"] {
    background: none !important;
    background-position: 100% -192px !important;
    border: none !important;
    float: none !important;
    font-family: "Myriad Pro", "Helvetica", sans-serif !important;
    height: auto !important;
    line-height: 1.5em !important;
    margin: 0 !important;
    padding: 5px 9px !important;
    padding-right: 20px !important;
    width: 100% !important;
}

/** chosen-results */

.chosen-container .chosen-results {
    color: black;
    margin: 0;
    max-height: 240px;
    padding: 0;
}

.chosen-container .chosen-results li {
    line-height: 1.5em;
    margin: 0;
    padding: 5px 9px;
}

.chosen-container .chosen-results li.disabled-result {
    color: #BBB;
}

.chosen-container .chosen-results li.highlighted {
    background: #CCC;
    color: black;
}

.chosen-container .chosen-results li.no-results {
    background: white;
    color: #BBB;
}

.chosen-container .chosen-results li em {
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
}

.chosen-rtl .chosen-search input[type="text"],
.chosen-container-single .chosen-single abbr,
.chosen-container-single .chosen-single div b,
.chosen-container-single .chosen-search input[type="text"],
.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
.chosen-container .chosen-results-scroll-down span,
.chosen-container .chosen-results-scroll-up span {
    background-image: url('../images/sprites@2x.png') !important;
    background-repeat: no-repeat !important;
    background-size: 25px 250px !important;
}
