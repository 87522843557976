/** status */

.status
{
    background: #888 !important;
    color: white !important;
}

.status-approved
{
    background: #76B243 !important;
}

.status-draft
{
    background: #888 !important;
}

.status-pending
{
    background: #F15922 !important;
}

.status-rejected,
.status-overdue
{
    background: #E61400 !important;
}

.status-verified
{
    background: #76B243 !important;
}

/** text */

.text-approved
{
    color: #76B243 !important;
}

.text-pending
{
    color: #F15922 !important;
}

.text-rejected,
.text-overdue
{
    color: #E61400 !important;
}

.text-verified
{
    color: #76B243 !important;
}
