*
{
    box-sizing: border-box;
}

html,
body
{
    height: 100%;
}

body
{
    margin: 0;
    padding: 0;
}

body,
button,
input,
select,
table,
textarea
{
    font-family: "Myriad Pro", "Helvetica", sans-serif;
    font-size: 14px;
    line-height: 1.5em;
}

h1
{
    font-size: 2.5em;
    font-weight: normal;
    line-height: 1.2em;
    margin: 0.6em 0;
}

h2
{
    font-size: 1.5em;
    font-weight: normal;
    line-height: 1em;
    margin: 1em 0;
}

h3
{
    font-size: 1em;
    font-weight: normal;
    line-height: 1.5em;
    margin: 1.5em 0;
}

p
{
    margin: 1.5em 0;
}

ol, ul
{
    margin: 1.5em 0;
    padding: 0;
}

li
{
    margin: 1em 0;
    margin-left: 20px;
    padding: 0;
}

a
{
    color: #76B243;
    text-decoration: none;
}

a img
{
    border: none;
}

hr
{
    background: none;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 1.5em 0;
}

img
{
    vertical-align: top;
}

sup
{
    bottom: 0.6em;
    font-size: 0.8em;
    position: relative;
    vertical-align: baseline;
}

/** backgrounds */

.background-white
{
    background: white !important;
    color: black !important;
}

.background-light
{
    background: #F1F2F2 !important;
    color: black !important;
}

.background-mid
{
    background: #666 !important;
    color: white !important;
}

/** borders */

.border-left
{
    border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-bottom
{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

/** clearfix */

.cf:before,
.cf:after
{
    clear: both;
    content: " ";
    display: block;
}

/** displays */

.display-none
{
    display: none !important;
}

/** floats */

.float-left
{
    float: left !important;
}

.float-right
{
    float: right !important;
}

/** font styles */

.font-style-oblique
{
    font-style: oblique !important;
}

/** line height */

.line-height-l
{
    line-height: 3em !important;
}

/** lists */

.list-none
{
    margin: 1.5em 0 !important;
    padding: 0 !important;
}

.list-none li
{
    list-style: none !important;
    margin: 0 !important;
    margin-bottom: 0.75rem !important;
    padding: 0 !important;
}

/** margins */

.margin-none
{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.margin-s
{
    margin-top: 0.75em !important;
    margin-bottom: 0.75em !important;
}

.margin-m
{
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
}

.margin-l
{
    margin-top: 3em !important;
    margin-bottom: 3em !important;
}

.margin-top-s
{
    margin-top: 0.5em !important;
}

/** text-bold */

.text-bold
{
    font-weight: bold !important;
}

/** text-alignment */

.text-align-left,
.text-align-l
{
    text-align: left !important;
}

.text-align-center,
.text-align-c
{
    text-align: center !important;
}

.text-align-right,
.text-align-r
{
    text-align: right !important;
}

/** text-color */

.text-color-gray
{
    color: #888 !important;
}

.text-color-orange
{
    color: #F15922 !important;
}

.text-color-red
{
    color: #E61400 !important;
}

/** text-decoration */

.text-decoration-underline
{
    text-decoration: underline;
}

/** text-spacing */

.text-spacing
{
    margin: 0 10px !important;
}

/** attachments */

.attachment
{
    margin: -5px 0;
    padding: 5px 9px;
}

.attachment .icon
{
    margin-right: 4px;
}

/** photos */

.photo-l
{
    border-radius: 5px;
    height: 200px;
    width: 200px;
}

/** instructions */

.instructions
{
    background: #F9EE92;
    border-radius: 5px;
    margin: 1.5em 0;
    padding: 2px 20px;
}

/** expiry notifications */

#expiry-notifications {
    background: #F1F2F2;
    border-bottom: 1px solid #CCC;
    padding: 0 20px;
}

/** error-list */

.error-list
{
    background-color: #C33;
    padding: 0.1em 1em;
}

@media screen and (max-width: 480px) {

    .hidden-mobile
    {
        display: none !important;
    }

    .block-mobile
    {
        display: block !important;
    }

}

.menu
{
    z-index: 200;
}

.text-muted
{
    color: #888;
}

.message-warning
{
    background: #FFCFBF;
    border: 1px solid #F15922;
    border-radius: 2px;
    padding: 10px;
}

.message-warning a
{
    color: #F15922;
}

.message-info
{
    background: #EEEEEE;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    padding: 10px;
}
