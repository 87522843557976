#navigation #logo
{
    background: #76B243;
    border-bottom: 1px solid #333;
    height: 80px;
}

#navigation #logo a
{
    background-color: none;
    background-image: url(../images/logo@2x.png);
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: 200px 80px;
    display: block;
    height: 100%;
    text-indent: -10000px;
    width: 100%;
}

#navigation #logo a:hover
{
    background-color: #693;
}

@media screen and (max-width: 1000px) {
    
    #navigation #logo a
    {
        background-image: url(../images/logo-stacked@2x.png);
        background-position: 50% 0;
        background-size: 100px 80px;
    }
    
}

@media screen and (max-width: 480px) {
    
    #navigation #logo a
    {
        background-image: url(../images/logo@2x.png);
        background-position: 25% 0;
        background-size: 200px 80px;
    }
    
}

/** .layout-learning-master **/

.layout-learning-master #navigation #logo
{
    background: white;
    border-right: 1px solid #666;
}

.layout-learning-master #navigation #logo a
{
    background-image: url(../images/learning-logo@2x.png);
    background-position: 20px 0;
    background-size: 200px 80px;
}

.layout-learning-master #navigation #logo a:hover
{
    background-color: white;
}

@media screen and (max-width: 1000px) {
    
    .layout-learning-master #navigation #logo a
    {
        background-image: url(../images/learning-logo@2x.png);
        background-position: 20px 15px;
        background-size: 120px 48px;
    }
    
}

@media screen and (max-width: 480px) {
    
    .layout-learning-master #navigation #logo a
    {
        background-image: url(../images/learning-logo@2x.png);
        background-position: 20px 0;
        background-size: 200px 80px;
    }
    
}

