.split-list
{
    background: white;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 66%;
    top: 0;
}

.split-list a
{
    color: black;
    display: block;
}

.split-list .subtitle
{
    color: #888;
}

.split-list .selected
{
    background: #69C;
}

.split-list .selected *
{
    color: white;
}

.split-content
{
    bottom: 0;
    left: 34%;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 0;
}

@media screen and (max-width: 480px) {
    
    .split-list
    {
        bottom: auto;
        left: auto;
        position: relative;
        right: auto;
        top: auto;
    }
    
    .split-content
    {
        bottom: auto;
        left: auto;
        position: relative;
        right: auto;
        top: auto;
    }
    
}
