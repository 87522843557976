table,
.table
{
    background: white;
    border: 1px solid #CCC;
    border-collapse: collapse;
    clear: both;
    margin: 1.5em 0;
    overflow: visible;
    padding: 0;
    padding-bottom: 10px;
    position: relative;
}

.table-container {
    margin: 1.5em 0;
    max-width: 100%;
    overflow-x: scroll;
    padding-bottom: 10px;
}

.table-container table,
.table-container .table {
    margin: 0;
    padding: 0;
}

table.extended
{
    width: 100%;
}

.table:before,
.table:after
{
    clear: both;
    content: " ";
    display: block;
}

table thead,
.table .table-head
{
    background: rgba(255, 255, 255, 0.9);
}

table th,
table td,
.table .col
{
    margin: 0;
    padding: 10px 20px;
    vertical-align: top;
}

table a,
.table a
{
    color: black;
    display: block;
}

table a.button,
.table a.button
{
    color: white;
}

/** head */

table thead,
.table .table-head
{
}

table thead tr,
.table .table-head .table-row
{
    border-bottom: 1px solid #DDD;
}

table thead tr:hover,
.table .table-head .table-row:hover
{
    background: none;
}

table thead th,
table thead td,
.table .table-head .col
{
    color: #888;
}

table tbody.with-head,
.table .table-content.with-head
{
}

/** rows */

table tr,
.table .table-row
{
    border-bottom: 1px solid #DDD;
    color: black;
}

.table .table-row:before,
.table .table-row:after
{
    clear: both;
    content: " ";
    display: block;
}

table tr:hover,
.table .table-row:hover
{
    background: #EEE;
}

/** section head */

table tr.section,
table tr.section:hover,
.table .table-section-head,
.table .table-section-head:hover
{
    background: #666;
    color: white;
}

/** section subheader */

.table .table-section-subheader,
.table .table-section-subheader:hover
{
    background: #ddd;
    color: #333;
}

/** fixed */

table.fixed,
.table.fixed
{
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
}

table.fixed tbody,
.table.fixed .table-content
{
}

@media screen and (max-width: 480px) {

    table,
    .table
    {
    }

    table th,
    table td,
    .table .col
    {
    }

    table button,
    table .button,
    table input,
    table .token,
    .table button,
    .table .button,
    .table input,
    .table .token
    {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    table input[type="checkbox"],
    .table input[type="checkbox"]
    {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    table thead,
    .table .table-head
    {
        display: none !important;
    }

    table tbody,
    .table .table-content
    {
    }

    table tbody.with-head,
    .table .table-content.with-head
    {
        padding-top: 0 !important;
    }

    table tr,
    .table .table-row
    {
        padding: 10px 15px !important;
    }

}
