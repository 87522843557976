.statistic-group
{
    clear: both;
    margin: 1.5em 0;
}

.statistic-group:before,
.statistic-group:after
{
    clear: both;
    content: " ";
    display: block;
}

.statistic
{
    background: white;
    border: 1px solid #CCC;
    border-radius: 2px;
    margin: 10px 0;
    padding: 20px 0;
    text-align: center;
}

.statistic-value
{
    font-size: 3em;
    line-height: 1em;
}

.statistic-label
{
    color: #888;
}
