.tabs-tabs
{
    background: white;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 75%;
    top: 0;
}

.tabs-tabs ul
{
    margin: 0;
    padding: 0;
}

.tabs-tabs li
{
    list-style: none;
    margin: 0;
    padding: 0;
}

.tabs-tabs a
{
    border-bottom: 1px solid #EEE;
    color: #AAA;
    cursor: pointer;
    display: block;
    padding: 10px 20px;
}

.tabs-tabs a.active
{
    color: black;
}

.tabs-tabs a.with-error
{
    background: #C33 !important;
    color: white !important;
}

.tabs-tabs a.with-error.active
{
    color: black !important;
}

.tabs-tabs a.with-error:after
{
    content: '\e83e';
    display: block;
    float: right;
    font-family: icons;
    font-size: 16px;
    height: 21px;
    line-height: 21px;
    vertical-align: middle;
    width: 14px;
}

.tabs-destination
{
    bottom: 0;
    left: 25%;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 0;
}

@media screen and (max-width: 480px) {
    
    .tabs-tabs
    {
        bottom: auto;
        left: auto;
        position: static;
        right: auto;
        top: auto;
    }
    
    .tabs-tabs a
    {
        padding: 10px 15px;
    }
    
    .tabs-destination
    {
        bottom: auto;
        left: auto;
        position: static;
        right: auto;
        top: auto;
    }
    
}
