html.layout-page,
html.layout-page body,
html.layout-page #page
{
    height: auto;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: relative;
    width: 100%;
}

#page-logo
{
    margin: 20px auto;
    padding: 0;
    width: 60%;
}

#page-logo img
{
    height: 150px;
    width: auto;
}

#page-main
{
    background: #EEE;
    border-radius: 5px;
    margin: 20px auto;
    padding: 0;
    width: 60%;
}

#page-main .form-actions
{
    margin: 2.25em 0;
    margin-left: 25%;
}

@media screen and (max-width: 480px) {
    
    #page-logo
    {
        margin: 20px 15px;
        width: auto;
    }
    
    #page-logo img
    {
        height: auto;
        width: 50%;
    }
    
    #page-main
    {
        border-radius: 0;
        width: 100%;
    }
    
}
