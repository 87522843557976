.toolbar
{
    background: white;
    border-bottom: 1px solid #CCC;
    height: 61px;
    left: 0;
    padding: 20px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 300;
}

.toolbar:before,
.toolbar:after
{
    clear: both;
    content: " ";
    display: block;
}

.toolbar.toolbar-front
{
    z-index: 500;
}

.toolbar .toolbar-left
{
    float: left;
    margin: 0 -5px;
    width: 75%;
    display: inline-flex;
}

.toolbar .toolbar-left-long
{
    width: 90%;
    display: inline-block;
}

.toolbar .toolbar-right
{
    float: right;
    margin: 0 -5px;
}

.toolbar .toolbar-item
{
    /*float: left;*/
    margin: 0 5px;
    padding: 0;
    display: inline-block;
}

.toolbar .toolbar-item-m
{
    width: 100px;
}

.toolbar .toolbar-item-l
{
    /*width: 150px;*/
}

.toolbar .toolbar-item-l input,
.toolbar .toolbar-item-l select{
    padding: 5px 0px;
}

.toolbar .toolbar-item-xl
{
    width: 300px;
}

.toolbar button,
.toolbar .button
{
    margin: -6px 0;
    padding: 6px 10px;
}

.toolbar .chosen-container
{
    margin: 0;
    padding: 0;
}

.toolbar form
{
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1200px) {

    .toolbar
    {
        background: white;
        border-bottom: 1px solid #CCC;
        height: 100px;
        left: 0;
        padding: 20px 20px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 300;
    }

    .toolbar.with-banner
    {
        top: 85px;
    }

    .toolbar .toolbar-item
    {
        padding-bottom: 5px;
        padding-top: 5px;
        margin-top: 3px;
        margin-bottom: 3px;
    }

}

@media screen and (max-width: 992px) {

    .toolbar.with-banner
    {
        height: 145px;
    }
}

@media screen and (max-width: 768px) {
    .toolbar
    {
        bottom: auto;
        height: auto;
        left: auto;
        padding: 10px 15px;
        position: static;
        right: auto;
        top: auto;
    }

    .toolbar.with-banner
    {
        position: absolute;
        height: 180px;
    }

    .toolbar .toolbar-left,
    .toolbar .toolbar-right
    {
        float: left;
        margin: 0 -5px;
        padding: 0;
        width: 100%;
    }

    .toolbar .toolbar-item-l
    {
        width: auto;
    }

    .toolbar .toolbar-item
    {
        margin: 10px 5px;
    }

    .toolbar .toolbar-item input,
    .toolbar .toolbar-item select,
    .toolbar .toolbar-item button
    {
        padding:5px 8px;
    }
}

@media screen and (max-width: 678px) {

    .toolbar
    {
        position: static;
    }

    .toolbar.with-banner
    {
        height: 220px;
    }
}

@media screen and (max-width: 576px) {

    .toolbar.with-banner
    {
        height: 220px;
    }
}

@media screen and (max-width: 480px) {

    .toolbar
    {
        bottom: auto;
        height: auto;
        left: auto;
        padding: 10px 15px;
        position: static;
        right: auto;
        top: auto;
    }

    .toolbar.with-banner
    {
        position: relative !important;
        top: auto !important;
    }

    .toolbar .toolbar-left,
    .toolbar .toolbar-right
    {
        float: left;
        margin: 0 -5px;
        padding: 0;
        width: 100%;
    }

    .toolbar .toolbar-item-l
    {
        width: auto;
    }

    .toolbar .toolbar-item
    {
        margin: 10px 5px;
    }

    .toolbar .toolbar-item input,
    .toolbar .toolbar-item select,
    .toolbar .toolbar-item button
    {
        padding:5px 8px;
    }

}