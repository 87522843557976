body .fc
{
	font-family: "Myriad Pro", "Helvetica", sans-serif;
	font-size: 14px;
}

.fc *
{
	outline: none;
}

/* fc-toolbar */

.fc .fc-toolbar
{
	margin: 0;
	padding: 15px;
	padding-bottom: 1em;
}

.fc .fc-toolbar h2
{
	font-size: 1.5em;
	font-weight: normal;
	margin-top: 8px;
}

/* tables and related elements */

.fc table,
.fc th,
.fc .fc-view .fc-day-number,
.fc .fc-view .fc-event
{
	font-size: 1em;
	font-weight: normal;
	line-height: 1.5em;
	text-align: left;
}

.fc table,
.fc tr
{
	background: none;
	border: none;
	margin: 0;
	padding: 0;
}

.fc table tr:hover
{
	background: none;
}

.fc .fc-row
{
	border-bottom: 1px solid #EEE;
}

.fc th,
.fc td
{
	border: none;
}

.fc th
{
	color: #BBB;
	margin: 0;
	padding: 0 5px;
	padding-left: 7px;
}

.fc td.fc-today
{
	background: #F8F8F8;
	border: none;
}

/* fc-month-view */

.fc .fc-month-view .fc-day-number
{
	color: black;
	margin: 0;
	padding: 0 5px;
	padding-left: 7px;
}

.fc .fc-month-view .fc-event-container
{
	margin: 0;
	padding: 0;
	padding-right: 2px;
}

.fc .fc-month-view .fc-event .fc-time
{
	display: none;
}

.fc .fc-month-view .fc-event .fc-time
{
	display: none;
}

.fc .fc-month-view .fc-event .fc-title
{
	font-size: 1em;
	font-weight: normal;
}

/* fc-basic-view */

.fc .fc-basic-view .fc-content-skeleton
{
	padding: 5px 0;
}

/* fc-agenda-view */

.fc .fc-agenda-view .fc-axis
{
	color: #BBB;
}

.fc .fc-agenda-view .fc-day-grid .fc-row
{
	border-bottom: none;
	min-height: 1.5em;
}

.fc .fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton
{
	margin: 0;
	padding: 2px 0;
}

.fc .fc-agenda-view .fc-day-grid .fc-row .fc-event-container
{
	padding-right: 2px;
}

.fc .fc-agenda-view .fc-divider
{
	background: black;
	border: none;
	margin: 0;
	padding: 0;
	padding-bottom: 1px;
}

.fc .fc-agenda-view .fc-slats td
{
	border-top-color: #DDD;
	border-top-style: solid;
	border-top-width: 1px;
	height: 2.25em;
}

.fc .fc-agenda-view .fc-slats .fc-minor td
{
	border-top-color: #DDD;
	border-top-style: dashed;
	border-top-width: 1px;
}

.fc .fc-agenda-view .fc-event-container
{
	margin: 0;
	margin-right: 2px;
	padding: 0;
}

/* fc-event */

.fc-event
{
	background: #DDD;
	background: rgba(200, 200, 200, 0.8);
	border: none;
	border-left: 2px solid #BBB;
	border-radius: 0;
	color: black;
	cursor: pointer;
	margin: 0;
	padding: 0 5px;
}

.fc-event:hover
{
	background: #BBB;
	background: rgba(180, 180, 180, 0.8);
	border: none;
	border-left: 2px solid #999;
	color: black;
}

.fc .fc-h-event.fc-not-start,
.fc .fc-h-event.fc-not-end
{
	border: none !important;
	border-left: 2px solid #BBB !important;
	margin: 0 !important;
	padding: 0 5px !important;
}

.fc .fc-h-event.fc-not-start
{
	border-left: none !important;
	padding-left: 7px !important;
}

/* event-status- */

.fc-event.event-status-accepted
{
	background: #76B243;
	border-left: 2px solid #592;
}

.fc-event.event-status-accepted:hover
{
	background: #592;
	border-left: 2px solid #592;
}

.fc-event.event-status-declined
{
	background: #E54358;
	border-left: 2px solid #C23;
}

.fc-event.event-status-declined:hover
{
	background: #C23;
	border-left: 2px solid #C23;
}

.fc-event.event-status-pending
{
	background: #EC6641;
	border-left: 2px solid #C42;
}

.fc-event.event-status-pending:hover
{
	background: #C42;
	border-left: 2px solid #C42;
}

.fc-event.event-status-filled
{
	background: #76B243;
	border-left: 2px solid #592;
}

.fc-event.event-status-filled:hover
{
	background: #592;
	border-left: 2px solid #592;
}

.fc-event.event-status-partiallyfilled
{
	background: #EC6641;
	border-left: 2px solid #C42;
}

.fc-event.event-status-partiallyfilled:hover
{
	background: #C42;
	border-left: 2px solid #C42;
}

.fc-event.event-status-nonefilled
{
	background: #E54358;
	border-left: 2px solid #C23;
}

.fc-event.event-status-nonefilled:hover
{
	background: #C23;
	border-left: 2px solid #C23;
}

/* fc-time-grid fc-event */

.fc .fc-time-grid .fc-event
{
	margin: 0;
	padding: 5px 6px;
}

.fc .fc-time-grid .fc-event .fc-time
{
	color: rgba(0, 0, 0, 0.4);
	font-size: 1em;
	font-weight: normal;
}

.fc .fc-time-grid .fc-event .fc-title
{
	font-size: 1em;
	font-weight: normal;
}

/* fc-button */

.fc button
{
	border-radius: 0;
	height: auto;
	line-height: 1.5em;
	margin: 0;
	padding: 10px 20px;
}

.fc .fc-state-default
{
	background: none;
	border: none;
	box-shadow: none;
	color: #BBB;
	text-shadow: none;
}

.fc .fc-state-hover,
.fc .fc-state-active
{
	color: black;
}

.fc .fc-state-disabled
{
	color: #BBB;
}

.fc-state-default.fc-corner-left
{
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.fc-state-default.fc-corner-right
{
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

/* fc-button-group */

.fc .fc-right .fc-button-group .fc-state-default,
.fc .fc-right .fc-button-group .fc-state-hover,
.fc .fc-right .fc-button-group .fc-state-active
{
	background: none;
	color: #BBB;
	font-weight: normal;
}

.fc .fc-right .fc-button-group .fc-state-hover,
.fc .fc-right .fc-button-group .fc-state-active
{
	color: black;
}

/* fc-icon */

.fc button .fc-icon
{
	height: auto;
	margin: 0;
	left: 0;
	padding: 0;
	position: relative;
	top: 0;
}

.fc button .fc-icon:after
{
	font-family: 'icons';
	font-size: 1em;
	font-weight: normal;
	line-height: 1.5em;
	left: 0;
	position: relative;
	top: 0;
}

.fc .fc-icon-left-single-arrow:after
{
	content: '\e874';
}

.fc .fc-icon-right-single-arrow:after {
	content: '\e875';
}
