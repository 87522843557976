.group
{
    border-bottom: 1px solid #DDD;
    margin: 0;
    margin-bottom: 1.5em;
    padding: 0;
    position: relative;
}

.group .group-title
{
    margin: 0.6em 0;
    padding: 0;
}

.group .group-content
{
    margin: 1.5em 0;
}
