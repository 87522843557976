.token
{
    background: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    display: block;
    margin: -4px 0;
    padding: 4px 10px;
    text-align: center;
}

.token .icon
{
    color: white;
    margin-left: 10px;
}

/** token-list */

.token-list
{
    margin: 1.5em -4px;
    padding: 0;
}

.token-list:before,
.token-list:after
{
    clear: both;
    content: " ";
    display: block;
}

.token-list .token
{
    background: #76B243;
    color: white;
    cursor: pointer;
    display: block;
    float: left;
    margin: 0.25em 0.25em;
}

.token-list .token:hover
{
    background: #592;
}

/** token-other */

.token-other:before,
.token-other:after
{
    clear: both;
    content: " ";
    display: block;
}

.token-other-input
{
    float: left;
    padding-right: 10px;
}

.token-other-token
{
    float: left;
}

.token-other-token .token-list
{
    margin: 0;
    padding: 0;
}

.token-other-token .token
{
    margin: -4px 0;
}

