.dragdrop-destination
{
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    height: 250px;
    margin: 1.5em 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    padding-bottom: 10px;
}

.dragdrop-disabled .dragdrop-destination
{
    background: none;
}

.dragdrop-item
{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 1.5em;
    list-style: none;
    margin: 0;
    padding: 0.75em 10px;
}

.dragdrop-text
{
    padding-right: 10px;
}

.dragdrop .token-other
{
    margin: 1.5em 0;
}
