#banner
{
    border-bottom: 1px solid #CCC;
    top: 0;
    height: 85px;
    left: 0;
    padding: 20px 20px;
    position: absolute;
    right: 0;
    z-index: 400;
}

.banner-image
{
    float: left;
    margin: -8px 0;
    margin-right: -60px;
    width: 60px;
}

.banner-image img
{
    border-radius: 30px;
    height: 60px;
    width: 60px;
}

.banner-image img.laurier-image
{
    border-radius: 0;
    height: 60px;
    width: 150px;
}

.banner-image-right
{
    float: right;
    margin: -8px 5px;
    width: auto;
}

.banner-text
{
    float: left;
    margin: 0;
    margin-left: 75px;
    padding: 0;
}

.banner-text.without-image
{
    margin-left: 5px;
}

.banner-text *
{
    margin: 0;
    padding: 0;
}

.banner-text .name
{
    font-size: 1.5em;
}

.banner-text .position
{
    color: #888;
}

.banner-actions
{
    margin: 0;
    padding: 0;
    position: absolute;
    right: 25px;
    top: 32px;
}

.banner-item
{
    float: left;
    margin: 0 5px;
    padding: 0;
}

.banner-description
{
    color: #888;
}

@media screen and (max-width: 480px) {
    
    #banner
    {
        bottom: 0 !important;
        height: auto !important;
        left: 0 !important;
        overflow-x: hidden !important;
        overflow-y: auto !important;
        padding: 20px 15px !important;
        position: relative !important;
        right: 0 !important;
        top: 0 !important;
        width: 100% !important;
    }

    .banner-text
    {
        float: none;
    }
    
    .banner-actions
    {
        clear: both !important;
        margin: 0 !important;
        margin-left: 70px !important;
        padding: 0 !important;
        position: relative !important;
        right: auto !important;
        top: auto !important;
    }
    
}
