.page-errors
{
    background: #C33;
    color: white;
    left: 0;
    min-height: 80px;
    padding: 20px 20px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
}