.dashboard-item
{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4.5em;
}

.dashboard-image
{
    float: left;
    margin: -12px 0;
    margin-right: -60px;
    width: 60px;
}

.dashboard-image img
{
    border-radius: 30px;
    height: 60px;
    width: 60px;
}

.dashboard-name
{
    margin-left: 75px;
}
