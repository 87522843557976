.grid:before,
.grid:after
{
    clear: both;
    content: " ";
    display: block;
}

.col
{
    float: left;
}

.col:before,
.col:after
{
    clear: both;
    content: " ";
    display: block;
}

/** grid input */

.grid-input
{
    float: left;
    margin: 0;
    margin-right: -40px;
    width: 40px;
}

.with-grid-input
{
    float: left;
    margin-left: 40px;
    width: calc(100% - 40px);
}

/** gutter */

.with-gutter-s
{
    margin: 0 -5px !important;
    padding: 0 !important;
}

.with-gutter-m
{
    margin: 0 -10px !important;
    padding: 0 !important;
}

.with-gutter-l
{
    margin: 0 -30px !important;
    padding: 0 !important;
}

.gutter-s
{
    margin: 0 !important;
    padding: 0 5px !important;
}

.gutter-m
{
    margin: 0 !important;
    padding: 0 10px !important;
}

.gutter-l
{
    margin: 0 !important;
    padding: 0 30px !important;
}

/** 1 column grid */

.col-1-1
{
    width: 100%;
}

/** 2 column grid */

.col-1-2
{
    width: 50%;
}

.col-2-2
{
    width: 100%;
}

/** 3 column grid */

.col-1-3
{
    width: 33.33%;
}

.col-2-3
{
    width: 66.66%;
}

.col-3-3
{
    width: 100%;
}

/** 4 column grid */

.col-1-4
{
    width: 25%;
}

.col-2-4
{
    width: 50%;
}

.col-3-4
{
    width: 75%;
}

.col-4-4
{
    width: 100%;
}

/** 5 column grid */

.col-1-5
{
    width: 20%;
}

.col-2-5
{
    width: 40%;
}

.col-3-5
{
    width: 60%;
}

.col-4-5
{
    width: 80%;
}

.col-5-5
{
    width: 100%;
}

/** 6 column grid */

.col-1-6
{
    width: 16.66%;
}

.col-2-6
{
    width: 33.33%;
}

.col-3-6
{
    width: 50%;
}

.col-4-6
{
    width: 66.66%;
}

.col-5-6
{
    width: 83.33%;
}

.col-6-6
{
    width: 100%;
}

/** 7 column grid */

.col-1-7
{
    width: 14.28%;
}

.col-2-7
{
    width: 28.57%;
}

.col-3-7
{
    width: 42.85%;
}

.col-4-7
{
    width: 57.14%;
}

.col-5-7
{
    width: 71.42%;
}

.col-6-7
{
    width: 85.71%;
}

.col-7-7
{
    width: 100%;
}

/** 8 column grid */

.col-1-8
{
    width: 12.5%;
}

.col-2-8
{
    width: 25%;
}

.col-3-8
{
    width: 37.5%;
}

.col-4-8
{
    width: 50%;
}

/** 10 column grid */

.col-1-10
{
    width: 10%;
}

.col-2-10
{
    width: 20%;
}

/** 12 column grid */

.col-1-12
{
    width: 8.3%;
}

.col-2-12
{
    width: 16.6%;
}

/** 14 column grid */

.col-1-14
{
    width: 7%;
}

.col-2-14
{
    width: 14%;
}

.col-3-14
{
    width: 21%;
}

.col-4-14
{
    width: 28%;
}

.col-5-14
{
    width: 35%;
}

/** 16 column grid */

.col-1-16
{
    width: 6%;
}

.col-2-16
{
    width: 12%;
}

.col-3-16
{
    width: 18%;
}

.col-4-16
{
    width: 24%;
}

.col-5-16
{
    width: 30%;
}

.col-6-16
{
    width: 36%;
}

.col-7-16
{
    width: 42%;
}

.col-8-16
{
    width: 48%;
}

.col-9-16
{
    width: 54%;
}

.col-10-16
{
    width: 60%;
}

.col-11-16
{
    width: 66%;
}

.col-12-16
{
    width: 72%;
}

.col-13-16
{
    width: 78%;
}

.col-14-16
{
    width: 84%;
}

.col-15-16
{
    width: 90%;
}

.col-16-16
{
    width: 96%;
}

/** 20 column grid */

.col-1-20
{
    width: 5%;
}

.col-2-20
{
    width: 10%;
}

.col-3-20
{
    width: 15%;
}

.col-4-20
{
    width: 20%;
}

.col-5-20
{
    width: 25%;
}

.col-6-20
{
    width: 30%;
}

@media screen and (max-width: 480px) {
    
    .col
    {
        width: 100% !important;
    }
    
}
