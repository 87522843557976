.social
{
    margin: 0 -2px;
}

.social a
{
    background: #76B243;
    border-radius: 5px;
    color: white;
    margin: -12px 2px;
    padding: 12px 5px;
}

.social a:hover
{
    background: #592;
}
