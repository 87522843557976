.ui-dialog
{
    left: 40px;
    margin: 0;
    padding: 0;
    right: 40px;
    top: 40px;
}

.ui-dialog .ui-dialog-titlebar
{
    border-bottom: 1px solid #BBB;
    border-radius: 0 !important;
    display: none;
    padding: 10px;
}

.ui-dialog .ui-dialog-title
{
    margin: 0;
    width: 100%;
}

.ui-dialog .ui-dialog-titlebar-close
{
    right: 10px;
}

.ui-dialog .ui-dialog-content
{
    margin: 0;
    padding: 0;
}

.ui-dialog .ui-dialog-buttonpane
{
    margin: 0;
    padding: 15px 15px;
}

.ui-dialog .ui-dialog-buttonpane button
{
    background: #888;
    margin: -5px 5px;
    padding: 5px 20px;
}

.ui-dialog .ui-dialog-buttonpane button:focus,
.ui-dialog .ui-dialog-buttonpane button:hover
{
    background: #666;
}

.dialog
{
    background: #F1F2F2 !important;
    display: none;
}

.dialog-content
{
    margin: 0 20px !important;
    padding: 0 !important;
}

/** chooser */

.chooser
{
    max-height: none !important;
    min-height: 0 !important;
}

.chooser .table
{
    border-bottom: 1px solid #BBB !important;
}

.chooser .table-content
{
    padding-bottom: 5px !important;
}

/** confirm-dialog */

.confirm-content
{
    margin: 0 20px !important;
    padding: 0 !important;
}

/** help-dialog */

.help-dialog
{
    background: #F9EE92 !important;
}

.help-content
{
    margin: 0 20px !important;
    padding: 0 !important;
}

/** modal-dialog */

.modal-dialog {
    display: none;
}

@media screen and (max-width: 480px) {

    .ui-dialog
    {
        left: 0;
        right: 0;
        top: 0;
    }

}
