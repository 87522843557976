.icon
{
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}

.icon:before
{
    display: block;
    font-size: 14px;
    line-height: 21px;
    vertical-align: middle;
}

.icon-m:before
{
    font-size: 16px;
}

.icon-l:before
{
    font-size: 21px;
}

.create-icon
{
    color: #76B243;
}
