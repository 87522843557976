.list
{
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    clear: both;
    margin: 1.5em 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    padding-bottom: 10px;
    position: relative;
}

.list.fixed
{
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
}

.list .list-item
{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 10px 20px;
    position: relative;
}

.list .list-item::before,
.list .list-item::after
{
    clear: both;
    content: " ";
    display: block;
    position: relative;
}

.list .list-date
{
    color: #888;
    float: right;
}

.list .list-image
{
    left: 20px;
    margin-top: -30px;
    position: absolute;
    top: 50%;
    height: 60px;
    width: 60px;
}

.list .list-image img
{
    border-radius: 30px;
    height: 60px;
    width: 60px;
}

.list .with-image
{
    float: left;
    margin-left: 80px;
}

.list .list-actions
{
    margin-top: 15px;
    margin-bottom: 5px;
}

/** section head */

.list .list-section-head
{
    background: #666;
    color: white;
}
